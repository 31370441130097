import React, { Fragment } from "react";
import Dashboard from './Dashboard';
import Induction from './Induction/Induction';
import Orchard from './Induction/Orchard';
import Database from './DatabaseV2.js'
import OrchardLanguage from './Induction/OrchardLanguage'
import OrchardEnglish from './Induction/OrchardEnglish'
import PackhouseLanguage from "./Induction/packhouse/PackhouseLanguage";
import PackhouseEnglish from "./Induction/packhouse/PackhouseEnglish";
import Packhouse from "./Induction/packhouse/Packhouse";
import Fields from "./Induction/fields/Fields";
import FieldsEnglish from "./Induction/fields/FieldsEnglish";
import FieldsLanguage from "./Induction/fields/FieldsLanguage";
import Fruitshop from "./Induction/fruitshop/Fruitshop";
import FruitshopEnglish from "./Induction/fruitshop/FruitshopEnglish";
import CornHarvest from "./Induction/corn/CornHarvest.js";
import CornPacking from "./Induction/corn/CornPacking.js";
import FruitshopLanguage from "./Induction/fruitshop/FruitshopLanguage";
import { BrowserRouter as Router, Routes, Switch, Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion';
import Register from "./login/register";


function AnimatedRoutes() {
    const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter>
        <Routes key={location.pathname} location={location}>
        <Route path='/' element={<Dashboard />} />
        <Route path='/induction' element={<Induction />} />
        <Route path='/orchard' element={<OrchardEnglish />} />
        <Route path='/packhouse' element={<PackhouseEnglish />} />
        <Route path='/fields' element={<FieldsEnglish />} />
        <Route path='/fruitshop' element={<FruitshopEnglish />} />
        <Route path='/corn-harvest' element={<CornHarvest />} />
        <Route path='/corn-packing' element={<CornPacking />} />
        <Route path="/register" element={<Register />} />
        <Route path="/Database" element={<Database />} />
        <Route path='*' element={<Dashboard />} />
        </Routes>
    </AnimatePresence>
  )
}

export default AnimatedRoutes